$colors: (
  bg-primary: #428aa0,
  bg-secondary: #a93c15,
  bg-tertiary: #006634,
  bg-quartary: #d5e5ea,
  bg-quinary: #549816,
  bg-senary: #377284,
  txt-primary: #555,
  txt-secondary: #fff
);
