@import 'mixins';
@import 'variables';

footer {
  .custom {
    margin-left: 15%;
  }
}

header {
  nav {
    ul {
      flex-direction: column;
      position: absolute;
      top:73px;
      left: -36%;
      background-color: get-color('bg-tertiary');
      width: 150%;
      transition: opacity 500ms;
      &.hidden {
        opacity: 0;
      }
      li {
        width: 100%;
        border-bottom: 1px solid get-color('txt-secondary');
        padding: 5px 0;
        a {
          color: get-color('txt-secondary');
          font-size: 16px;
          &:hover {
            color: get-color('bg-quinary');
          }
        }
      }
    }
    .menu-button {
      display: block;
      width: 50px;
      cursor: pointer;
      margin-left: 80%;
      img {
        width: 100%;
      }
    }
  }
}

main {
  .about-us {
    flex-direction: column;
    .image {
      width: 50%;
      margin: {
        left: auto;
        right: auto;
      }
    }
    .text {
      width: 100%;
    }
  }
  .near {
    .nav-images {
      justify-content: space-around;
      figure {
        width: 40%;
        margin:10px 0;
      }
    }
  }

  .sights {
    .sight {
      .desc {
        width: 80%;
      }
      figure {
        width: 80%;
      }
    }
  }
}